/**
 * SuggestedTradesStatus Enum
 */
export const SuggestedTradesStatus = {
  NEEDS_UPDATE: 'NEEDS_UPDATE',
  NO_ACTION: 'NO_ACTION',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type SuggestedTradesStatus =
  typeof SuggestedTradesStatus[keyof typeof SuggestedTradesStatus]
