import { ToastType } from 'types'

import FlexBox from 'components/common/FlexBox/FlexBox'
import Text from 'components/common/Text/Text'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  title: string
  description?: string
  type: ToastType
}

export function CustomToast({ title, description, type }: Props) {
  let borderLeftColor

  switch (type) {
    case 'Error':
      borderLeftColor = COLOR.red600
      break
    case 'Success':
      borderLeftColor = COLOR.emerald600
      break
    default:
      borderLeftColor = COLOR.purple800
      break
  }

  return (
    <FlexBox
      marginTop={SPACE.sp20}
      width="25rem"
      backgroundColor={COLOR.white}
      padding="1rem 1.125rem"
      borderLeft={SPACE.sp6}
      borderLeftColor={borderLeftColor}
      borderStyle="solid"
      borderRadius={SPACE.sp4}
      flexDirection="column"
    >
      <Text fontSize={FONT_SIZE.fs16} fontWeight={700} lineHeight={SPACE.sp24}>
        {title}
      </Text>
      <Text fontSize={FONT_SIZE.fs14} fontWeight={400} lineHeight={SPACE.sp20}>
        {description}
      </Text>
    </FlexBox>
  )
}
