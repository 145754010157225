import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { analytics } from 'analytics'
import React, { useEffect } from 'react'
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link'
import { useNavigate, useParams } from 'react-router-dom'

import { paveApi } from 'api'
import { Button } from 'components/common'
import {
  PORTFOLIO_CREATE_PLAID_LINK_COMPLETED,
  PORTFOLIO_CREATE_PLAID_LINK_STARTED,
  PORTFOLIO_CREATE_PLAID_SUBMITTED,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { setPortfolioId } from 'store/createPortfolioSlice'
import { closeCreatePortfolio } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

type Props = {
  isOpen: boolean
  onClose: () => void
  goNext: () => void
  isThirdPartyManaged: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

function LinkPlaidModal({
  isOpen,
  onClose,
  goNext,
  setShowModal,
  isThirdPartyManaged,
}: Props) {
  const { showErrorToast } = useCustomToast()
  const navigate = useNavigate()
  const [createLinkToken, { data }] = paveApi.useCreatePlaidLinkTokenMutation()
  const [createPlaidBrokerageAccount] =
    paveApi.useCreatePlaidBrokerageAccountMutation()
  const [createEmptyPortfolio] = paveApi.useCreateEmptyPortfolioMutation()

  const dispatch = useAppDispatch()

  const { displayName } = useAppSelector((state) => state.createPortfolio)

  const { externalId } = useParams()
  const { data: clientAccountResponse } = paveApi.useGetClientAccountQuery(
    externalId || '',
    {
      skip: !externalId,
    }
  )

  useEffect(() => {
    createLinkToken({})
  }, [createLinkToken])

  const onSuccess: PlaidLinkOnSuccess = (publicToken, _metadata) => {
    analytics.track(PORTFOLIO_CREATE_PLAID_LINK_COMPLETED)
    setShowModal(false)
    createEmptyPortfolio({
      displayName,
      isThirdPartyManaged,
      clientAccountId: clientAccountResponse?.clientAccount?.id,
    })
      .unwrap()
      .then((data) => {
        dispatch(setPortfolioId(data.id))
        createPlaidBrokerageAccount({ publicToken, portfolioId: data.id })
          .unwrap()
          .then(() => {
            analytics.track(PORTFOLIO_CREATE_PLAID_SUBMITTED)
            navigate(`/portfolio/${data.id}`)
            dispatch(closeCreatePortfolio())
          })
      })
      .catch((e) => {
        const displayMessage = e?.data?.displayMessage
        if (displayMessage) {
          showErrorToast(displayMessage)
        } else {
          showErrorToast('There was an error creating the portfolio.')
        }
      })
  }

  const { open, ready } = usePlaidLink({
    token: data?.link_token || null,
    onSuccess,
  })

  const handleClose = () => {
    analytics.track(PORTFOLIO_CREATE_PLAID_LINK_STARTED)
    onClose()
    open()
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          setShowModal(false)
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Brokerage Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Linking a broker will automatically pull in and update your current
            portfolio and available cash from the brokerage account of your
            choosing. As a result, your existing current portfolio and available
            cash settings will be replaced.
          </ModalBody>

          <ModalFooter>
            <StyledButton
              color="purple.800"
              onClick={handleClose}
              disabled={!ready}
            >
              Link Broker
            </StyledButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const StyledButton = styled(Button)`
  width: 100%;
`

export default LinkPlaidModal
