import { LinkIcon } from '@heroicons/react/solid'

import { InvestmentTemplate } from 'types'

import { Box, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { openEditInvestmentTemplate } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function InvestmentTemplatesListItem(template: InvestmentTemplate) {
  const { displayName, portfoliosCount } = template

  const dispatch = useAppDispatch()

  const handleEditInvestmentTemplate = () => {
    dispatch(openEditInvestmentTemplate(template))
  }

  return (
    <InteractiveCard
      alignItems="center"
      display="flex"
      height="48px"
      justifyContent="space-evenly"
      onClick={handleEditInvestmentTemplate}
      style={{ cursor: 'pointer' }}
      width="100%"
    >
      <Box display="flex" flex={2}>
        <Text
          className="interactive-text"
          fontWeight="bold"
          fontSize={FONT_SIZE.fs12}
          lineHeight="xs"
          color={COLOR.coolGray600}
        >
          {displayName}
        </Text>
      </Box>

      <FlexBox flex={1} gap={SPACE.sp12} justifyContent="flex-end">
        <Box width="16px" height="16px" color={COLOR.coolGray500}>
          <LinkIcon />
        </Box>
        <Text
          fontWeight="medium"
          fontSize={FONT_SIZE.fs12}
          lineHeight="xs"
          color={COLOR.coolGray700}
        >
          {`${portfoliosCount} Portfolios`}
        </Text>
      </FlexBox>
    </InteractiveCard>
  )
}

export default InvestmentTemplatesListItem
