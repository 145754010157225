import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { analytics } from 'analytics'
import { useState } from 'react'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { paveApi } from 'api'
import { Box, Button, Dropdown, Text } from 'components/common'
import BulletList from 'components/common/BulletList/BulletList'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { DropdownOptionString } from 'components/partials/DropdownWithTags'
import {
  LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED,
  LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED_FAILED,
  LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import ModalLoader from '../ModalLoader'

type Props = {
  isOpen: boolean
  onClose: () => void
  accountIds: string[]
  accountNames: string[]
  clientAccounts: ClientAccountDto[]
  refetch: () => void
}
function SchwabAccountGroupAssignmentModal({
  isOpen,
  onClose,
  accountIds,
  accountNames,
  clientAccounts,
  refetch,
}: Props) {
  const [selectedClientAccount, setSelectedClientAccount] = useState<
    any | null
  >(null)

  const { showErrorToast, showSuccessToast } = useCustomToast()

  const [linkSchwabAccount, { isLoading }] =
    paveApi.useLinkSchwabAccountMutation()
  const clientAccountOptions = clientAccounts?.map((c) => {
    return {
      value: c.id,
      label: c.displayName,
    }
  }) as DropdownOptionString[]

  const handleSave = () => {
    analytics.track(LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED)
    if (selectedClientAccount) {
      linkSchwabAccount({
        id: selectedClientAccount?.value,
        body: {
          accountIds,
        },
      })
        .unwrap()
        .then((response) => {
          if (response.status === 'Error') {
            analytics.track(LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED_FAILED)
            showErrorToast('Error linking account')
          } else {
            analytics.track(LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED_SUCCESS)
            onClose()
            showSuccessToast('Account linked')
            refetch()
          }
        })
        .catch(() => {
          analytics.track(LINK_SUB_ACCOUNT_TO_GROUP_MODAL_SUBMITTED_FAILED)
          showErrorToast('Error linking account')
        })
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay bg="rgba(0, 0, 0, 0.2)" />
      <ModalContent>
        <ModalHeader>
          <Box>
            <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs16}>
              Assign to group
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingX={SPACE.sp24}>
          <Dropdown
            label="Group"
            placeholder="Select an option"
            options={clientAccountOptions}
            onChange={(option: unknown) => setSelectedClientAccount(option)}
            value={selectedClientAccount}
          />
          <FlexBox
            flexDirection="column"
            gap={SPACE.sp8}
            marginTop={SPACE.sp10}
          >
            <Text
              color={COLOR.coolGray600}
              fontSize={FONT_SIZE.fs14}
              fontWeight={500}
            >
              Accounts Selected
            </Text>
            <BulletList items={accountNames} />
          </FlexBox>
        </ModalBody>
        <ModalFooter marginBottom={SPACE.sp8}>
          <Box marginBottom={SPACE.sp12} width="100%">
            <Button
              onClick={handleSave}
              size="md"
              variant="primary"
              width="100%"
              disabled={!selectedClientAccount || isLoading}
            >
              Save
            </Button>
            {isLoading && <ModalLoader />}
          </Box>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
export default SchwabAccountGroupAssignmentModal
