import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import { round } from 'lodash'

import { HttpStatus } from 'enums'
import { Sector } from 'protos/ts/domain/types'
import {
  Allocation,
  Asset,
  AssetOverviewInfo,
  Benchmark,
  GetInvestmentTemplatesResponse,
  GetPortfolioStatsResponse,
  Industry,
  InvestmentTemplate,
  StatusResponseDto,
  SubAccountInfo,
  UpdateAutomationRequest,
  UpdateHoldingsManuallyRequest,
  UpdatePreferencesRequest,
} from 'types'
import {
  BulkApplyInvestmentTemplateRequestDto,
  BulkApplyInvestmentTemplateResponseDto,
  BulkRemoveInvestmentTemplateRequestDto,
  BulkRemoveInvestmentTemplateResponseDto,
  CreateInvestmentTemplateRequestDto,
  UpdateInvestmentTemplateRequestDto,
  UpdateInvestmentTemplateResponseDto,
} from 'types/InvestmentTemplateTypes'
import { BuyListName } from 'types/buyListTypes'
import {
  CreateBuyListRequestDto,
  EditBuyListRequestDto,
  GetBuyListNamesResponseDto,
  GetBuyListResponseDto,
  GetBuyListsResponseDto,
  GetChangeLogResponseDto,
  UpdateBuyListAssetsWithFileRequestDto,
  UpdateBuyListAssetsWithFileResponseDto,
} from 'types/dto/BuyList.dto'
import {
  ClientAccountDto,
  ClientAccountMemberDto,
  CreateClientAccountDto,
  GetBreakdownsResponseDto,
  GetClientAccountResponse,
  GetClientAccountsRequest,
  GetClientAccountsResponse,
  GetPortfoliosResponseDto,
  LinkSchwabAccountInput,
  LinkSchwabAccountResponseDto,
} from 'types/dto/ClientAccount.dto'
import {
  IBSsoSessionRequest,
  SSOSessionResponseDto,
} from 'types/dto/InteractiveBrokers.dto'
import {
  LinkedManagementOrganizationAccountResponseDto,
  ManagementOrganizationDto,
} from 'types/dto/ManagementOrganization.dto'
import { Portfolio } from 'types/index'
import { PlaidResponse } from 'types/plaid'

import { API_TAG, BASE_URL, FEATURE_FLAGS } from 'constants/app.constants'
import { signOut } from 'store/authSlice'
import { decryptJsonPayload, encryptJsonPayload } from 'utils/encryption'
import { getCountryName, getStateName } from 'utils/select'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, api) => {
    const store: any = api.getState()
    if (!!store.auth.IdToken) {
      headers.set('Authorization', `Bearer ${store.auth.IdToken}`)
    }
    if (!!store.auth.AccessToken) {
      headers.set('Access', `${store.auth.AccessToken}`)
    }
    if (FEATURE_FLAGS.ENABLE_ENCRYPTION) {
      headers.set('x-content-encrypted', 'true')
    }
    return headers
  },
})

const baseQueryWithEncryption: BaseQueryFn<
  string | (FetchArgs & { isFilePayload?: boolean }),
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  if (FEATURE_FLAGS.ENABLE_ENCRYPTION) {
    if (typeof args === 'object' && args.body) {
      // isFilePayload is a custom flag to not encrypt the request files
      // Currently do not have an implementation to encrypt FormData objects
      const canEncrypt = args.isFilePayload !== true

      if (canEncrypt) {
        const encryptedBody = await encryptJsonPayload(
          JSON.stringify(args.body)
        )
        args.body = {
          encryptedBody: encryptedBody,
        }
      }
    }
  }

  let result: any = await baseQuery(args, api, extraOptions)

  // Log out user if the request returns status 401 or 403
  if (
    result?.error?.status === HttpStatus.UNAUTHORIZED ||
    result?.error?.status === HttpStatus.FORBIDDEN
  ) {
    api.dispatch(signOut())
  }

  const encryptionHeader = result?.meta?.response?.headers?.get(
    'x-content-encrypted'
  )

  if (encryptionHeader === 'true') {
    const decryptedJson = await decryptJsonPayload(
      result.data.encryptedResponse
    )
    result.data = JSON.parse(decryptedJson)
  }
  return result
}

const {
  GROUP,
  INVESTMENT_PREFERENCES_TEMPLATE,
  LINKED_ACCOUNT,
  PERFORMANCE,
  PORTFOLIO,
  BUY_LISTS,
  BUY_LIST_CHANGE_LOG,
} = API_TAG

const paveApi = createApi({
  reducerPath: 'paveApi',
  baseQuery: baseQueryWithEncryption,
  tagTypes: [GROUP, PERFORMANCE, PORTFOLIO, INVESTMENT_PREFERENCES_TEMPLATE],
  endpoints: (builder) => ({
    signIn: builder.mutation<any, { email: string; password: string }>({
      query: ({ email, password }: { email: string; password: string }) => ({
        method: 'POST',
        url: '/auth/login',
        body: { email, password },
      }),
    }),
    signUp: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: '/auth/signup',
        body,
      }),
    }),
    confirmUser: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: '/auth/confirm',
        body,
      }),
    }),
    resendConfirmationCode: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: '/auth/resendConfirmationCode',
        body,
      }),
    }),
    forgotPassword: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: '/auth/forgotPassword',
        body,
      }),
    }),
    confirmForgotPassword: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: '/auth/confirmForgotPassword',
        body,
      }),
    }),
    associateSoftwareToken: builder.mutation<any, void>({
      query: () => ({
        method: 'POST',
        url: '/auth/associateSoftwareToken',
      }),
    }),
    verifyMfaSetup: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: '/auth/verifyMfaSetup',
        body,
      }),
    }),
    verifySoftwareTokenMfa: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: '/auth/verifySoftwareTokenMfa',
        body,
      }),
    }),
    getUserDetails: builder.mutation<any, void>({
      query: () => ({
        method: 'POST',
        url: '/auth/getUserDetails',
      }),
    }),
    setUserMFAPreference: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: '/auth/setUserMFAPreference',
        body,
      }),
    }),
    getUserById: builder.query<any, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/users/${id}`,
      }),
    }),
    updateUser: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'PUT',
        url: `/users/${body.id}`,
        body,
      }),
    }),
    getManagementOrganzation: builder.query<ManagementOrganizationDto, string>({
      query: (managementOrganizationId: string) => ({
        method: 'GET',
        url: `/management-organizations/${managementOrganizationId}`,
      }),
    }),
    getLinkedAccounts: builder.query<
      LinkedManagementOrganizationAccountResponseDto[],
      string
    >({
      query: (managementOrganizationId: string) => ({
        method: 'GET',
        url: `/management-organizations/linked-accounts/${managementOrganizationId}`,
      }),
      providesTags: [LINKED_ACCOUNT],
    }),
    getManagementOrganzationAccountsInfo: builder.query<
      SubAccountInfo[],
      string
    >({
      query: (managementOrganizationId: string) => {
        return {
          method: 'GET',
          url: `/management-organizations/managementOrganizationAccountsInfo/${managementOrganizationId}`,
        }
      },
    }),
    getManagementOrganizationInvestmentTemplates: builder.query<
      GetInvestmentTemplatesResponse,
      string
    >({
      query: (managementOrganizationId: string) => {
        return {
          method: 'GET',
          url: `/management-organizations/investment-preferences-templates/${managementOrganizationId}`,
        }
      },
      providesTags: [INVESTMENT_PREFERENCES_TEMPLATE],
    }),
    createInvestmentTemplate: builder.mutation<
      InvestmentTemplate,
      CreateInvestmentTemplateRequestDto
    >({
      query: (body) => {
        return {
          method: 'POST',
          url: `/investment-preferences-templates`,
          body,
        }
      },
      invalidatesTags: [INVESTMENT_PREFERENCES_TEMPLATE],
    }),
    deleteInvestmentTemplate: builder.mutation<string, string>({
      query: (id: string) => {
        return {
          method: 'DELETE',
          url: `/investment-preferences-templates/${id}`,
        }
      },
      invalidatesTags: [INVESTMENT_PREFERENCES_TEMPLATE],
    }),
    editInvestmentTemplate: builder.mutation<
      UpdateInvestmentTemplateResponseDto,
      UpdateInvestmentTemplateRequestDto
    >({
      query: (request) => {
        const { templateId, body } = request
        return {
          method: 'PUT',
          url: `/investment-preferences-templates/update-template/${templateId}`,
          body,
        }
      },
      invalidatesTags: [INVESTMENT_PREFERENCES_TEMPLATE, PORTFOLIO],
    }),
    bulkApplyInvestmentTemplate: builder.mutation<
      BulkApplyInvestmentTemplateResponseDto,
      BulkApplyInvestmentTemplateRequestDto
    >({
      query: (request: BulkApplyInvestmentTemplateRequestDto) => ({
        method: 'PUT',
        url: `/investment-preferences-templates/bulk-apply-template/${request.templateId}`,
        body: request,
      }),
      invalidatesTags: [PORTFOLIO],
    }),
    bulkRemoveInvestmentTemplate: builder.mutation<
      BulkRemoveInvestmentTemplateResponseDto,
      BulkRemoveInvestmentTemplateRequestDto
    >({
      query: (request: BulkRemoveInvestmentTemplateRequestDto) => ({
        method: 'PUT',
        url: `/investment-preferences-templates/bulk-remove-template-from-portfolios/`,
        body: request,
      }),
    }),
    getClientAccounts: builder.query<
      ClientAccountDto[],
      GetClientAccountsRequest
    >({
      query: (request) => {
        const { managementOrgId } = request
        return {
          method: 'GET',
          url: `/management-organizations/${managementOrgId}/clientAccounts`,
        }
      },
      transformResponse(response: GetClientAccountsResponse) {
        return response?.clientAccounts?.map((account) => {
          const balance = account.portfolios.reduce(
            (acc, portfolio) => acc + portfolio.value,
            0
          )
          const gainPercentage = account.portfolios.reduce(
            (acc, portfolio) =>
              acc + portfolio.performance * (portfolio.value / balance),
            0
          )
          const formattedAccount = {
            ...account,
            balance,
            gainPercentage: round(gainPercentage, 2),
            primaryContact: {
              ...account?.primaryContact,
              countryName: getCountryName(account?.primaryContact?.country),
              stateName: getStateName(
                account?.primaryContact?.state,
                account?.primaryContact?.country
              ),
            },
          }
          return formattedAccount
        })
      },
    }),
    getClientAccount: builder.query<GetClientAccountResponse, string>({
      query: (clientAccountId: string) => ({
        method: 'GET',
        url: `/client-accounts/${clientAccountId}`,
      }),
      providesTags: [GROUP],
    }),
    createClientAccount: builder.mutation<any, CreateClientAccountDto>({
      query: (body: CreateClientAccountDto) => ({
        method: 'POST',
        url: '/client-accounts',
        body,
      }),
    }),
    updateClientAccount: builder.mutation<
      any,
      Partial<CreateClientAccountDto> & { id: string }
    >({
      query: (body: Partial<CreateClientAccountDto> & { id: string }) => ({
        method: 'PUT',
        url: `/client-accounts/${body.id}`,
        body,
      }),
      invalidatesTags: (_result, error) => (error ? [] : [GROUP]),
    }),
    deleteClientAccount: builder.mutation<any, string>({
      query: (id: string) => ({
        method: 'DELETE',
        url: `/client-accounts/${id}`,
      }),
    }),
    addClientAccountMember: builder.mutation<
      any,
      ClientAccountMemberDto & { clientAccountId: string }
    >({
      query: (body: ClientAccountMemberDto & { clientAccountId: string }) => ({
        method: 'POST',
        url: `/client-accounts/${body.clientAccountId}/members`,
        body,
      }),
    }),
    removeClientAccountMember: builder.mutation<
      any,
      ClientAccountMemberDto & { clientAccountId: string }
    >({
      query: (body: ClientAccountMemberDto & { clientAccountId: string }) => ({
        method: 'DELETE',
        url: `/client-accounts/${body.clientAccountId}/members/${body.userId}`,
      }),
    }),
    linkSchwabAccount: builder.mutation<
      LinkSchwabAccountResponseDto,
      LinkSchwabAccountInput
    >({
      query: (input: LinkSchwabAccountInput) => ({
        method: 'POST',
        url: `/client-accounts/${input.id}/bulkSchwabCreate`,
        body: input.body,
      }),
    }),
    getSectors: builder.query<Sector[], void>({
      query: () => ({
        method: 'GET',
        url: `/sectors`,
      }),
    }),
    getSectorById: builder.query<Sector, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/sectors/${id}`,
      }),
    }),
    getIndustryById: builder.query<Industry, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/industries/${id}`,
      }),
    }),
    getAssets: builder.query<Asset[], void>({
      query: () => ({
        method: 'GET',
        url: `/assets`,
      }),
    }),
    getAsset: builder.query<Asset, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/assets/${id}`,
      }),
    }),
    getAssetDescription: builder.query<any, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/assets/${id}/description`,
      }),
    }),
    getAssetOverviewInfo: builder.query<AssetOverviewInfo, string>({
      query: (symbol: string) => ({
        method: 'GET',
        url: `/assets/assetOverview/${symbol}`,
      }),
    }),
    getBenchmarks: builder.query<Benchmark[], void>({
      query: () => ({
        method: 'GET',
        url: `/benchmarks`,
      }),
    }),
    getPortfolios: builder.query<any, string>({
      query: () => ({
        method: 'GET',
        url: `/portfolios`,
      }),
      providesTags: [PORTFOLIO],
    }),
    getClientAccountNamesAndIds: builder.query<
      ClientAccountDto[],
      GetClientAccountsRequest
    >({
      query: (request) => {
        const { managementOrgId } = request
        return {
          method: 'GET',
          url: `/management-organizations/${managementOrgId}/client-account-names-and-ids`,
        }
      },
      transformResponse(response: GetClientAccountsResponse) {
        return response?.clientAccounts
      },
    }),
    getClientAccountsSummary: builder.query<
      ClientAccountDto[],
      GetClientAccountsRequest
    >({
      query: (request) => {
        const { managementOrgId } = request
        return {
          method: 'GET',
          url: `/management-organizations/client-accounts-summary/${managementOrgId}`,
        }
      },
      transformResponse(response: GetClientAccountsResponse) {
        // TODO: add a transfomer as part of the request for this
        return response?.clientAccounts
      },
    }),
    postPortfolio: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: `/portfolios`,
        body,
      }),
      invalidatesTags: [GROUP, PORTFOLIO],
    }),
    createEmptyPortfolio: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: `/portfolios/createEmpty`,
        body,
      }),
      invalidatesTags: (result) => {
        return result ? [GROUP] : []
      },
    }),
    getPortfolio: builder.query<Portfolio, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/portfolios/${id}`,
      }),
      providesTags: (result: any) => {
        return result ? [{ type: PORTFOLIO, id: result.id }] : []
      },
    }),
    editPortfolio: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'PUT',
        url: `/portfolios/${body.id}`,
        body,
      }),
      invalidatesTags: [PORTFOLIO, GROUP],
    }),
    editPortfolioPreferences: builder.mutation<any, UpdatePreferencesRequest>({
      query: (body: UpdatePreferencesRequest) => ({
        method: 'PUT',
        url: `/portfolios/investment-plan/update-preferences/${body.id}`,
        body,
      }),
      invalidatesTags: [PORTFOLIO, GROUP, INVESTMENT_PREFERENCES_TEMPLATE],
    }),
    editHoldings: builder.mutation<any, UpdateHoldingsManuallyRequest>({
      query: ({ id, body }: UpdateHoldingsManuallyRequest) => ({
        method: 'PUT',
        url: `/portfolios/update-holdings-manually/${id}`,
        body,
      }),
      invalidatesTags: (result) => {
        return [{ type: PORTFOLIO, id: result?.id }]
      },
    }),
    editConnections: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'PUT',
        url: `/portfolios/${body.id}/updatePlaidAccountInfo`,
        body,
      }),
      invalidatesTags: [GROUP, PERFORMANCE, PORTFOLIO],
    }),
    patchPortfolio: builder.mutation<any, any>({
      query: ({ body }: any) => ({
        method: 'PATCH',
        url: `/portfolios/${body.id}`,
        body,
      }),
      invalidatesTags: (result, _error, { invalidatesTags = [] }) => {
        return result
          ? [{ type: PORTFOLIO, id: result?.id }, ...invalidatesTags]
          : []
      },
    }),
    patchAutomation: builder.mutation<any, UpdateAutomationRequest>({
      query: ({ id, body }: UpdateAutomationRequest) => ({
        method: 'PATCH',
        url: `/portfolios/update-automation/${id}`,
        body,
      }),
      invalidatesTags: [PORTFOLIO],
    }),
    deletePortfolio: builder.mutation<any, string>({
      query: (id: string) => ({
        method: 'DELETE',
        url: `/portfolios/${id}`,
      }),
      invalidatesTags: [GROUP, LINKED_ACCOUNT],
    }),
    refreshPortfolio: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: `/portfolios/${body.id}/refresh`,
        body,
      }),
      invalidatesTags: (result) => {
        return [{ type: PORTFOLIO, id: result?.id }]
      },
    }),
    updateSuggestedTrades: builder.mutation<Portfolio, string>({
      query: (id: string) => ({
        method: 'PUT',
        url: `/portfolios/${id}/suggestedTrades`,
      }),
      invalidatesTags: (result) => {
        return [{ type: PORTFOLIO, id: result?.id }]
      },
    }),
    emailSuggestedTrades: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: `/portfolios/${body.id}/suggestedTradesEmail`,
        body,
      }),
    }),
    getPortfoliosStats: builder.query<GetPortfolioStatsResponse, any>({
      query: (portfolioId: string) => ({
        method: 'GET',
        url: `/portfolios/${portfolioId}/stats-v2`,
      }),
    }),
    createPlaidLinkToken: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: `/plaid/createLinkToken`,
        body,
      }),
    }),
    createPlaidBrokerageAccount: builder.mutation<any, any>({
      query: (body: any) => ({
        method: 'POST',
        url: `/plaid/createPlaidBrokerageAccount`,
        body,
      }),
    }),
    getPlaidAccount: builder.query<PlaidResponse, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/plaid/accounts/${id}`,
      }),
    }),
    getIbSsoSession: builder.mutation<
      SSOSessionResponseDto,
      IBSsoSessionRequest
    >({
      query: (body: any) => ({
        method: 'POST',
        url: `/brokerage-account/ibSsoSession`,
        body,
      }),
    }),
    getClientAccountPortfolios: builder.query<GetPortfoliosResponseDto, string>(
      {
        query: (clientAccountExternalId: string) => ({
          method: 'GET',
          url: `/client-accounts/portfolios/${clientAccountExternalId}`,
        }),
      }
    ),
    getClientAccountBreakdowns: builder.query<GetBreakdownsResponseDto, string>(
      {
        query: (clientAccountExternalId: string) => ({
          method: 'GET',
          url: `/client-accounts/breakdowns/${clientAccountExternalId}`,
        }),
      }
    ),
    getPortfolioBreakdowns: builder.query<{ breakdowns: Allocation[] }, string>(
      {
        query: (portfolioId: string) => ({
          method: 'GET',
          url: `/portfolios/breakdowns/${portfolioId}`,
        }),
      }
    ),
    rebalancePortfolio: builder.mutation<StatusResponseDto, string>({
      query: (id: string) => ({
        method: 'PUT',
        url: `/portfolios/rebalance/${id}`,
      }),
    }),
    editBuyList: builder.mutation<StatusResponseDto, EditBuyListRequestDto>({
      query: (body: EditBuyListRequestDto) => ({
        method: 'PATCH',
        url: `/buy-lists/edit/${body.buyListId}`,
        body,
      }),
      invalidatesTags: [BUY_LISTS, BUY_LIST_CHANGE_LOG],
    }),
    getBuyList: builder.query<GetBuyListResponseDto, string>({
      query: (id: string) => ({
        method: 'GET',
        url: `/buy-lists/buy-list/${id}`,
      }),
      providesTags: (result: any) => {
        return result ? [{ type: BUY_LISTS, id: result.id }] : []
      },
    }),
    createBuyList: builder.mutation<StatusResponseDto, CreateBuyListRequestDto>(
      {
        query: (body: CreateBuyListRequestDto) => ({
          method: 'POST',
          url: `/buy-lists/create`,
          body,
        }),
        invalidatesTags: [BUY_LISTS],
      }
    ),
    getBuyLists: builder.query<GetBuyListsResponseDto, void>({
      query: () => ({
        method: 'GET',
        url: `/organizations/buy-lists`,
      }),
      providesTags: [BUY_LISTS],
    }),
    getBuyListNames: builder.query<BuyListName[], void>({
      query: () => ({
        method: 'GET',
        url: `/organizations/buy-list-names`,
      }),
      transformResponse(response: GetBuyListNamesResponseDto) {
        return response?.buyLists
      },
    }),
    uploadBuyListAssetsCsv: builder.mutation<
      UpdateBuyListAssetsWithFileResponseDto,
      UpdateBuyListAssetsWithFileRequestDto
    >({
      query: ({ body }) => ({
        method: 'POST',
        url: `/buy-lists/upload-buy-list-assets-csv`,
        headers: {
          'x-content-encrypted-res': String(FEATURE_FLAGS.ENABLE_ENCRYPTION),
        },
        body,
        isFilePayload: true,
      }),
    }),
    getBuyListChangeLog: builder.query<GetChangeLogResponseDto, string>({
      query: (buyListId: string) => ({
        method: 'GET',
        url: `/buy-lists/changelog/${buyListId}`,
      }),
      providesTags: [BUY_LIST_CHANGE_LOG],
    }),
  }),
})

export default paveApi
