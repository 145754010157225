import React from 'react'

import { setPlaidStep, setStep } from 'store/createPortfolioSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

import PlaidFlowStep1 from './PlaidFlowStep1'
import PlaidFlowStep2 from './PlaidFlowStep2'

function PlaidFlow() {
  const dispatch = useAppDispatch()
  const plaidStep = useAppSelector((state) => state.createPortfolio.plaidStep)
  const goBackToSelector = () => {
    dispatch(setStep(1))
    dispatch(setPlaidStep(1))
  }
  const goBack = () => {
    dispatch(setPlaidStep(plaidStep - 1))
  }
  const goNext = () => {
    dispatch(setPlaidStep(plaidStep + 1))
  }
  return (
    <>
      {plaidStep === 1 && (
        <PlaidFlowStep1 goBack={goBackToSelector} goNext={goNext} />
      )}
      {plaidStep === 2 && <PlaidFlowStep2 goBack={goBack} goNext={goNext} />}
    </>
  )
}

export default PlaidFlow
