import { InvestmentPreferenceAction, Portfolio, PortfolioLinkType } from 'types'
import { PortfolioFormPayload } from 'types/portfolioTypes'

import { paveApi } from 'api'
import { API_ERRORS } from 'api/errors'
import { Box, Button } from 'components/common'
import { PortfolioForm, SlideOutMenu } from 'components/partials'
import { useCustomToast } from 'hooks/useCustomToast'
import {
  buildUpdatePreferencesRequest,
  getInvestmentPreferenceAction,
  getPortfolioLinkType,
} from 'utils/portfolioUtil'

type Props = {
  closeModal: () => void
  portfolio: Portfolio
  showModal: boolean
}

function EditPortfolioSlideIn({ closeModal, portfolio, showModal }: Props) {
  const linkMethod = getPortfolioLinkType(portfolio)
  const isUnlinkedPortfolio = linkMethod === PortfolioLinkType.None

  const [editPortfolioPreferences, { isLoading: isEditLoading }] =
    paveApi.useEditPortfolioPreferencesMutation()
  const [patchPortfolio, { isLoading: isPatchLoading }] =
    paveApi.usePatchPortfolioMutation()
  const [patchAutomation, { isLoading: isPatchAutomationLoading }] =
    paveApi.usePatchAutomationMutation()
  const isDisabled = isEditLoading || isPatchLoading || isPatchAutomationLoading
  const { showErrorToast, showSuccessToast, showInfoToast } = useCustomToast()

  /**
   * Determines which request(s) to make based on selected form values
   */

  const submitEditPortfolioForm = async (
    portfolioPayload: PortfolioFormPayload
  ) => {
    // Ensure enableTaxOptimization is set to false for unlinked portfolios
    if (isUnlinkedPortfolio) {
      portfolioPayload.investmentPreference.enableTaxOptimization = false
    }
    // Map actions to requests
    const actions = {
      [InvestmentPreferenceAction.EDIT]: () => {
        const updatePreferencesRequest = buildUpdatePreferencesRequest(
          portfolio,
          portfolioPayload
        )

        return editPortfolioPreferences(updatePreferencesRequest)
      },
      [InvestmentPreferenceAction.PATCH_AUTOMATION]: () =>
        patchAutomation({
          id: portfolio.id,
          body: {
            automation: portfolioPayload.investmentPreference.automation,
          },
        }),
      [InvestmentPreferenceAction.PATCH_TEMPLATE_ID]: () =>
        patchPortfolio({
          body: {
            id: portfolio.id,
            investmentPreferencesTemplateId:
              portfolioPayload.investmentPreferencesTemplateId,
          },
        }),
    }

    // Perform request and handle success and error
    const performAction = async (
      actionFn: () => any,
      action: InvestmentPreferenceAction,
      handleClose = true
    ) =>
      actionFn()
        .unwrap()
        .then(() => {
          if (handleClose) {
            closeModal()
            handleSuccessToast(action)
          }
        })
        .catch((e: any) => handleError(e))

    // Error handler
    const handleError = (e: any) => {
      const { message, displayMessage, displayDescription } = e?.data || {}
      if (message === API_ERRORS.CONSTRAINT_LIMIT_EXCEEDED) {
        showErrorToast(
          'Suggested trades could not be generated',
          'Your investment preferences may be too stringent. Please try removing some exclusions or making more of your assets tradable. If the issue persists, please contact support@pavefinance.com'
        )
      } else if (displayMessage) {
        showErrorToast(displayMessage, displayDescription)
      } else {
        showErrorToast('An error occurred updating the portfolio.')
      }
    }

    // Success toast handler
    const handleSuccessToast = (action: InvestmentPreferenceAction) => {
      switch (action) {
        case InvestmentPreferenceAction.EDIT: // An optimization preference was changed. This also covers potential changes to template ID.
        case InvestmentPreferenceAction.EDIT_AND_PATCH_AUTOMATION: // An optimization preference field was changed AND automation was changed.
          showSuccessToast(
            'Suggested trades generated',
            'A new set of suggested trades was successfully generated for your portfolio'
          )
          break

        // Automation AND template ID were changed without impacting
        // any optimization preferences
        case InvestmentPreferenceAction.PATCH_AUTOMATION_AND_TEMPLATE_ID:
        case InvestmentPreferenceAction.PATCH_AUTOMATION: // Just automation was changed.
        case InvestmentPreferenceAction.PATCH_TEMPLATE_ID: // Just the template ID was changed without impacting any optimization preferences.
          showSuccessToast(
            'Investment preferences updated',
            'This portfolio’s investment preferences have been successfully updated'
          )
          break
      }
    }

    // Determine which request(s) to make
    const action: InvestmentPreferenceAction = getInvestmentPreferenceAction(
      portfolio,
      portfolioPayload
    )
    switch (action) {
      case InvestmentPreferenceAction.EDIT: // An optimization preference was changed. This also covers potential changes to template ID.
      case InvestmentPreferenceAction.PATCH_AUTOMATION: // Just automation was changed.
      case InvestmentPreferenceAction.PATCH_TEMPLATE_ID: // Just the template ID was changed without impacting any optimization preferences.
        await performAction(actions[action], action)
        break

      // An optimization preference field was changed
      // AND automation was changed.
      case InvestmentPreferenceAction.EDIT_AND_PATCH_AUTOMATION:
        await performAction(
          actions[InvestmentPreferenceAction.PATCH_AUTOMATION],
          action,
          false
        )
        await performAction(actions[InvestmentPreferenceAction.EDIT], action)
        break

      // Automation AND template ID were changed without impacting
      // any optimization preferences
      case InvestmentPreferenceAction.PATCH_AUTOMATION_AND_TEMPLATE_ID:
        await performAction(
          actions[InvestmentPreferenceAction.PATCH_AUTOMATION],
          action,
          false
        )
        await performAction(
          actions[InvestmentPreferenceAction.PATCH_TEMPLATE_ID],
          action
        )
        break

      // Nothing was changed
      case InvestmentPreferenceAction.NO_ACTION:
        showInfoToast('No changes detected.', 'Please try again.')
        break
    }
  }

  return (
    <>
      <SlideOutMenu
        isOpen={showModal}
        onClose={closeModal}
        title="Edit Investment Preferences"
        size="xl"
        formId="portfolio-form"
        disabled={isDisabled}
        customHeaderButtons={
          <Box display="flex" gap="12px">
            <Button type="submit" form="portfolio-form" disabled={isDisabled}>
              Save Changes
            </Button>
          </Box>
        }
      >
        <PortfolioForm
          initialPortfolio={portfolio}
          showLoader={isDisabled}
          submitPortfolioForm={submitEditPortfolioForm}
        />
      </SlideOutMenu>
    </>
  )
}
export default EditPortfolioSlideIn
