import { AssetClass, BenchmarkType } from 'enums'
import { PerformancePriority } from 'enums/PerformancePriorityEnum'
import { SuggestedTradesStatus } from 'enums/SuggestedTradesStatusEnum'
import { Portfolio } from 'types'
import { PerformancePriorityDropdownOption } from 'types/generalTypes'
import { PortfolioFormState } from 'types/portfolioTypes'

import { DropdownOptionString } from 'components/partials/DropdownWithTags'

/**
 * General Constants
 */
export const BALANCED = 'balanced'
export const DEFAULT = 'default'
export const DEFAULT_ENABLE_TAX_OPTIMIZATION = true
export const DEFAULT_IS_THIRD_PARTY_MANAGED = false
export const DEFAULT_TARGET_ASSET_COUNT = 55
export const DEFAULT_TARGET_CASH_WEIGHT_PLACEHOLDER = '4%'
export const DEFAULT_TARGET_CASH_WEIGHT = 0.04
export const DEFAULT_FRACTIONAL_SHARES = false

export const PAVE_SECURITIES_BROKER_NAME = 'Pave Securities'

export const MINIMUM_VALID_TARGET_CASH_WEIGHT_PERCENT = 1

/**
 * Shape and default values for forms editing and creating a portfolio
 * Good-to-have: If necessary create different defaults to reset
 */
export const DEFAULT_FORM_STATE: PortfolioFormState = {
  availableCash: 0,
  assets: [],
  benchmarkId: null,
  displayName: '',
  isThirdPartyManaged: false,
  userApprovedAutomatedTradesOnce: false,

  /** Investment Preference Fields */
  buyListId: null,
  investmentTemplateId: null,
  activeConnectedAccountIds: [],
  assetClass: AssetClass.EQUITY,
  automation: false,
  enableTaxOptimization: true,
  excludedAssets: [],
  excludedIndustries: [],
  excludedSectors: [],
  fractionalShares: DEFAULT_FRACTIONAL_SHARES,
  multiAssetClassStrategy: undefined,
  performancePriority: undefined,
  targetAssetCount: 0,
  // Factor Tilts
  factorTilts: [],
  globalMacroSensitivity: DEFAULT,
  interestRateSensitivity: DEFAULT,
  investmentStyle: BALANCED,
  usDollarSensitivity: DEFAULT,
  volatilityPreference: BALANCED,
}

/**
 * Portfolio shapes
 */
export const EMPTY_PORTFOLIO: Portfolio = {
  assets: [],
  availableCash: 0,
  benchmark: {
    id: '',
    name: '',
    code: '',
    type: BenchmarkType.STOCK,
  },
  benchmarkId: null,
  buyListId: null,
  brokerageAccounts: [],
  displayName: '',
  clientAccountId: '',
  gainPercentage: 0,
  id: '',
  investmentPreference: {
    assetClass: undefined,
    automation: false,
    enableTaxOptimization: undefined,
    excludedAssets: [],
    excludedIndustries: [],
    excludedSectors: [],
    factorTilts: [],
    fractionalShares: DEFAULT_FRACTIONAL_SHARES,
    multiAssetClassStrategy: undefined,
    performancePriority: undefined,
    targetAssetCount: 0,
  },
  investmentPreferencesTemplateId: null,
  isThirdPartyManaged: false,
  isTrading: false,
  needsTradeExecution: false,
  performance: 0,
  recommendedAssets: undefined,
  suggestedTrades: [],
  suggestedTradesStatus: SuggestedTradesStatus.NO_ACTION,
  statistics: null,
  trades: [],
  unsupportedAssets: [],
  userApprovedAutomatedTradesOnce: false,
  value: 0,
  createdAt: '',
  updatedAt: '',
}

export const EMPTY_PLAID_PORTFOLIO: Portfolio = {
  assets: [],
  availableCash: 0,
  benchmark: {
    id: '',
    name: '',
    code: '',
    type: BenchmarkType.STOCK,
  },
  buyListId: null,
  benchmarkId: null,
  brokerageAccounts: [],
  displayName: '',
  clientAccountId: '',
  gainPercentage: 0,
  id: '',
  investmentPreference: {
    assetClass: undefined,
    automation: false,
    enableTaxOptimization: DEFAULT_ENABLE_TAX_OPTIMIZATION,
    excludedAssets: [],
    excludedIndustries: [],
    excludedSectors: [],
    factorTilts: [],
    fractionalShares: DEFAULT_FRACTIONAL_SHARES,
    multiAssetClassStrategy: undefined,
    performancePriority: undefined,
    targetAssetCount: DEFAULT_TARGET_ASSET_COUNT,
  },
  investmentPreferencesTemplateId: null,
  isThirdPartyManaged: DEFAULT_IS_THIRD_PARTY_MANAGED,
  isTrading: false,
  needsTradeExecution: false,
  performance: 0,
  recommendedAssets: undefined,
  suggestedTrades: [],
  suggestedTradesStatus: SuggestedTradesStatus.NO_ACTION,
  statistics: null,
  trades: [],
  unsupportedAssets: [],
  userApprovedAutomatedTradesOnce: false,
  value: 0,
  createdAt: '',
  updatedAt: '',
}

export const DEFAULT_THIRD_PARTY_MANAGED_INVESTMENT_PREFERENCE = {
  performancePriority: undefined,
  enableTaxOptimization: undefined,
  excludedSectors: [],
  excludedIndustries: [],
  excludedAssets: [],
  targetAssetCount: 0,
  factorTilts: [],
  automation: false,
  fractionalShares: DEFAULT_FRACTIONAL_SHARES,
  targetCashWeight: undefined,
}

type DropdownOptionBoolean = {
  label: string
  value: boolean
}

interface PortfolioFormOptions {
  automationOptions: DropdownOptionBoolean[]
  fractionalTradingOptions: DropdownOptionBoolean[]
  investmentStyleOptions: DropdownOptionString[]
  volatilityPreferenceOptions: DropdownOptionString[]
  sensitivityOptions: DropdownOptionString[]
  performancePriorityOptions: PerformancePriorityDropdownOption[]
  performancePriorityOptionsWithTaxOptimizationOff: PerformancePriorityDropdownOption[]
  enableTaxOptimizationOptions: DropdownOptionBoolean[]
  assetClassOptions: DropdownOptionString[]
}
// Portfolio Form Options
export const portfolioFormOptions: PortfolioFormOptions = {
  // Trading
  automationOptions: [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ],
  fractionalTradingOptions: [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ],
  enableTaxOptimizationOptions: [
    { label: 'On', value: true },
    { label: 'Off', value: false },
  ],

  // Strategy
  investmentStyleOptions: [
    { label: 'Balanced (Default)', value: 'balanced' },
    { label: 'Growth', value: 'growth' },
    { label: 'Value', value: 'value' },
  ],
  volatilityPreferenceOptions: [
    { label: 'Balanced (Default)', value: 'balanced' },
    { label: 'Momentum', value: 'momentum' },
    { label: 'Min Vol', value: 'minVol' },
  ],
  sensitivityOptions: [
    { label: 'Balanced (Default)', value: 'default' },
    { label: 'Overweight', value: 'overweight' },
    { label: 'Underweight', value: 'underweight' },
  ],
  performancePriorityOptions: [
    {
      label: 'Tracking Error Focus',
      value: PerformancePriority.TRACKING_ERROR,
    },
    { label: 'Balanced', value: PerformancePriority.BALANCED },
    {
      label: 'Slight Outperformance Focus (Recommended)',
      value: PerformancePriority.SLIGHTLY_O,
    },
    {
      label: 'Outperformance Focus',
      value: PerformancePriority.OUTPERFORMANCE,
    },
    {
      label: 'Strong Outperformance Focus',
      value: PerformancePriority.STRONGLY_O,
    },
  ],
  performancePriorityOptionsWithTaxOptimizationOff: [
    {
      label: 'Tracking Error Focus',
      value: PerformancePriority.TRACKING_ERROR,
    },
    {
      label: 'Balanced (Recommended)',
      value: PerformancePriority.BALANCED,
    },
    {
      label: 'Slight Outperformance Focus',
      value: PerformancePriority.SLIGHTLY_O,
    },
    {
      label: 'Outperformance Focus',
      value: PerformancePriority.OUTPERFORMANCE,
    },
    {
      label: 'Strong Outperformance Focus',
      value: PerformancePriority.STRONGLY_O,
    },
  ],
  assetClassOptions: [
    { label: 'Equity Only', value: AssetClass.EQUITY },
    { label: 'Multi-Asset Class', value: AssetClass.MULTI_ASSET_CLASS },
  ],
}

export const DEFAULT_BENCHMARK_FOR_STATS = 'S&P 500'

export const DEFAULT_PERFORMANCE_PRIORITY = PerformancePriority.SLIGHTLY_O
export const DEFAULT_PERFORMANCE_PRIORITY_WITH_TAX_OPTIMIZATION_OFF =
  PerformancePriority.BALANCED
