import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react'

import { Box, Button, Text } from 'components/common'

type Props = {
  goBack: () => void
  goNext: () => void
}

function PlaidFlowStep1({ goBack, goNext }: Props) {
  return (
    <>
      <ModalHeader>Create New Portfolio</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text color="coolGray.500" fontWeight="600">
          Link Existing Brokerage Account
        </Text>
        <Text fontSize="sm" as="p">
          We will now link one of your existing brokerage accounts. Pave uses
          Plaid to facilitate this process, which is highly secure and will not
          require you to leave this webpage. You can expect the following steps:
        </Text>
        <Text fontSize="sm" as="p" mt="8px">
          1. Select the financial institution where your account sits
        </Text>
        <Text fontSize="sm" as="p">
          2. Enter your login credentials for that financial institution
        </Text>
        <Text fontSize="sm" as="p">
          3. Select the account you would like to link
        </Text>
        <Text fontSize="sm" as="p">
          4. Finalize your investment preferences
        </Text>
        <Text fontSize="sm" as="p" mt="8px">
          Press next to get started
        </Text>
        <ModalFooter px="0">
          <Box
            display="flex"
            width="100%"
            flexDirection="column"
            gap="12px"
            mt="12px"
          >
            <Button
              color="purple.800"
              style={{ width: '100%' }}
              onClick={goNext}
            >
              Next
            </Button>
            <Button variant="secondary" onClick={goBack} active={false}>
              Back
            </Button>
          </Box>
        </ModalFooter>
      </ModalBody>
    </>
  )
}

export default PlaidFlowStep1
