/**
 * ConnectionMethod Enum
 */

export const ConnectionMethod = {
  INTERACTIVE_BROKERS: 'INTERACTIVE_BROKERS',
  PLAID: 'PLAID',
  RIA_SUB_ACCOUNT: 'RIA_SUB_ACCOUNT',
  NONE: 'NONE',
} as const

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type ConnectionMethod =
  typeof ConnectionMethod[keyof typeof ConnectionMethod]
