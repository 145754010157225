import { analytics } from 'analytics'

import { InvestmentTemplateFormPayload } from 'types/InvestmentTemplateTypes'

import { paveApi } from 'api'
import { Box, Button } from 'components/common'
import { SlideOutMenu } from 'components/partials'
import { EMPTY_INVESTMENT_TEMPLATE } from 'constants/investmentTemplate.constants'
import {
  INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED,
  INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_FAILED,
  INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { SPACE } from 'styles/constants/space'

import InvestmentTemplateForm from './CreatePortfolio/InvestmentTemplateForm'

type Props = {
  closeModal: () => void
  showModal: boolean
  managementOrganizationId: string
  refetchTemplates: () => void
}

function AddInvestmentTemplateSlideIn({
  closeModal,
  showModal,
  managementOrganizationId,
  refetchTemplates,
}: Props) {
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const [createInvestmentTemplate, { isLoading }] =
    paveApi.useCreateInvestmentTemplateMutation()

  const submitInvestmentTemplateForm = (
    investmentTemplatePayload: InvestmentTemplateFormPayload
  ) => {
    analytics.track(INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED)
    createInvestmentTemplate({
      ...investmentTemplatePayload,
      managementOrganizationId,
    })
      .unwrap()
      .then(() => {
        showSuccessToast('Template created!')
        closeModal()
        analytics.track(INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_SUCCESS)
      })
      .catch((e) => {
        const displayMessage = e?.data?.displayMessage
        if (displayMessage) {
          showErrorToast(displayMessage)
        } else {
          showErrorToast('An error occurred when creating the template.')
        }

        analytics.track(INVESTMENT_TEMPLATE_CREATE_FORM_SUBMITTED_FAILED)
      })
    refetchTemplates()
  }

  return (
    <SlideOutMenu
      isOpen={showModal}
      onClose={closeModal}
      title="Create Investment Template"
      size="xl"
      formId="investment-template-form"
      customHeaderButtons={
        <Box display="flex" gap={SPACE.sp12}>
          <Button
            type="submit"
            disabled={isLoading}
            form="investment-template-form"
          >
            Save
          </Button>
        </Box>
      }
    >
      <InvestmentTemplateForm
        initialTemplate={EMPTY_INVESTMENT_TEMPLATE}
        isNewTemplate={true}
        submitInvestmentTemplateForm={submitInvestmentTemplateForm}
      />
    </SlideOutMenu>
  )
}
export default AddInvestmentTemplateSlideIn
