import { LinkIcon } from '@heroicons/react/solid'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { Box, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

function ConnectedAccountsGroupListItem({
  id,
  displayName,
  portfolios,
}: ClientAccountDto) {
  const navigate = useNavigate()

  const onAccountClick = (e: React.SyntheticEvent) => {
    e.preventDefault()
    navigate(`/account/${id}`)
  }

  return (
    <a onClick={onAccountClick} href={`/account/${id}`}>
      <InteractiveCard
        display="flex"
        justifyContent="space-evenly"
        alignItems="center"
        width="100%"
        height="48px"
      >
        <Box display="flex" flex={2}>
          <Text
            className="interactive-text"
            fontWeight="bold"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.coolGray600}
          >
            {displayName}
          </Text>
        </Box>

        <FlexBox flex={1} gap={SPACE.sp12} justifyContent="flex-end">
          <Box width="16px" height="16px" color={COLOR.coolGray500}>
            <LinkIcon />
          </Box>
          <Text
            fontWeight="medium"
            fontSize={FONT_SIZE.fs12}
            lineHeight="xs"
            color={COLOR.coolGray700}
          >
            {`${portfolios.length} Portfolios`}
          </Text>
        </FlexBox>
      </InteractiveCard>
    </a>
  )
}

export default ConnectedAccountsGroupListItem
