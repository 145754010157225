import { AssetClass } from 'enums'
import { PerformancePriority } from 'enums/PerformancePriorityEnum'
import { Asset, ReducerAction, Strategies } from 'types'
import { InvestmentTemplateFormState } from 'types/InvestmentTemplateTypes'
import { FactorTiltObject } from 'types/generalTypes'

import { BALANCED_OPTION, DEFAULT_OPTION } from 'constants/general.constants'
import { DEFAULT_INVESTMENT_TEMPLATE_FORM_STATE } from 'constants/investmentTemplate.constants'
import { getDefaultPerformancePriority } from 'utils/portfolioFormUtil'

/**
 * ACTION CONSTANTS
 */
const CLEAR_FACTOR_TILTS = 'CLEAR_FACTOR_TILTS'
const CLEAR_STRATEGY = 'CLEAR_STRATEGY'
const EVALUATE_IMPACTED_TAX_OPTIMIZATION_FIELDS =
  'EVALUATE_IMPACTED_TAX_OPTIMIZATION_FIELDS'
const DEFAULT_STATE = 'DEFAULT_STATE'
const SET_ASSETS = 'SET_ASSETS'
const SET_ASSET_CLASS = 'SET_ASSET_CLASS'
const SET_BENCHMARK_ID = 'SET_BENCHMARK_ID'
const SET_BUY_LIST_ID = 'SET_BUY_LIST_ID'
const SET_CLIENT_ACCOUNT_ID = 'SET_CLIENT_ACCOUNT_ID'
const SET_DISPLAY_NAME = 'SET_DISPLAY_NAME'
const SET_ENABLE_TAX_OPTIMIZATION = 'SET_ENABLE_TAX_OPTIMIZATION'
const SET_EXCLUDED_ASSETS = 'SET_EXCLUDED_ASSETS'
const SET_EXCLUDED_INDUSTRIES = 'SET_EXCLUDED_INDUSTRIES'
const SET_EXCLUDED_SECTORS = 'SET_EXCLUDED_SECTORS'
const SET_FRACTIONAL_SHARES = 'SET_FRACTIONAL_SHARES'
const SET_PERFORMANCE_PRIORITY = 'SET_PERFORMANCE_PRIORITY'
const SET_STRATEGY = 'SET_STRATEGY'
const SET_DEFAULT_TARGET_ASSET_COUNT = 'SET_DEFAULT_TARGET_ASSET_COUNT'
const SET_DEFAULT_TARGET_CASH_WEIGHT_PERCENT =
  'SET_DEFAULT_TARGET_CASH_WEIGHT_PERCENT'
const SET_USER_APPROVED_AUTOMATED_TRADES_ONCE =
  'SET_USER_APPROVED_AUTOMATED_TRADES_ONCE'
const SET_AUTOMATION = 'SET_AUTOMATION'

/** Factor Tilts */
const SET_FACTOR_TILTS = 'SET_FACTOR_TILTS'
const SET_GLOBAL_MACRO_SENSITIVITY = 'SET_GLOBAL_MACRO_SENSITIVITY'
const SET_INTEREST_RATE_SENSITIVITY = 'SET_INTEREST_RATE_SENSITIVITY'
const SET_INVESTMENT_STYLE = 'SET_INVESTMENT_STYLE'
const SET_US_DOLLAR_SENSITIVITY = 'SET_US_DOLLAR_SENSITIVITY'
const SET_VOLATILITY_PREFERENCE = 'SET_VOLATILITY_PREFERENCE'

/**
 * REDUCER
 */
export function investmentTemplateFormReducer(
  oldState: InvestmentTemplateFormState,
  action: ReducerAction
): InvestmentTemplateFormState {
  let state: InvestmentTemplateFormState = JSON.parse(JSON.stringify(oldState))
  switch (action.type) {
    case CLEAR_FACTOR_TILTS:
      state.globalMacroSensitivity = DEFAULT_OPTION.value
      state.interestRateSensitivity = DEFAULT_OPTION.value
      state.investmentStyle = BALANCED_OPTION.value
      state.usDollarSensitivity = DEFAULT_OPTION.value
      state.volatilityPreference = BALANCED_OPTION.value
      state.factorTilts = []
      break
    case CLEAR_STRATEGY:
      state.multiAssetClassStrategy = undefined
      break
    case DEFAULT_STATE:
      // Use in case a state refresh is needed
      const defaultState = JSON.parse(
        JSON.stringify(DEFAULT_INVESTMENT_TEMPLATE_FORM_STATE)
      )
      state = defaultState
      break
    case EVALUATE_IMPACTED_TAX_OPTIMIZATION_FIELDS:
      // Performance priority is set to default only if there is a factor tilt
      // selection or the current performance priority is selected on default
      //
      // This case should only be hit after updating tax optimization
      const defaultPerformancePriority = getDefaultPerformancePriority(state)
      const otherDefaultPriority =
        defaultPerformancePriority === PerformancePriority.SLIGHTLY_O
          ? PerformancePriority.BALANCED
          : PerformancePriority.SLIGHTLY_O
      const hasFactorTilts = Boolean(state.factorTilts?.length)

      if (
        hasFactorTilts ||
        state.performancePriority === otherDefaultPriority
      ) {
        state.performancePriority = defaultPerformancePriority
      }
      break
    case SET_ASSET_CLASS:
      // TODO: May need to reassign targetAssetCount if assigned to multi asset class
      state.assetClass = action.payload
      break
    case SET_BENCHMARK_ID:
      state.benchmarkId = action.payload
      break
    case SET_BUY_LIST_ID:
      state.buyListId = action.payload
      break
    case SET_DISPLAY_NAME:
      state.displayName = action.payload
      break
    case SET_ENABLE_TAX_OPTIMIZATION:
      state.enableTaxOptimization = action.payload
      break
    case SET_EXCLUDED_ASSETS:
      state.excludedAssets = action.payload
      break
    case SET_EXCLUDED_INDUSTRIES:
      state.excludedIndustries = action.payload
      break
    case SET_EXCLUDED_SECTORS:
      state.excludedSectors = action.payload
      break
    case SET_FRACTIONAL_SHARES:
      state.fractionalShares = action.payload
      break
    case SET_PERFORMANCE_PRIORITY:
      state.performancePriority = action.payload
      break
    case SET_STRATEGY:
      state.multiAssetClassStrategy = action.payload
      break
    case SET_DEFAULT_TARGET_ASSET_COUNT:
      state.targetAssetCount = action.payload
      break
    case SET_DEFAULT_TARGET_CASH_WEIGHT_PERCENT:
      state.targetCashWeightPercent = action.payload
      break
    // Factor Tilts
    case SET_FACTOR_TILTS:
      state.factorTilts = action.payload
      break
    case SET_GLOBAL_MACRO_SENSITIVITY:
      state.globalMacroSensitivity = action.payload
      break
    case SET_INTEREST_RATE_SENSITIVITY:
      state.interestRateSensitivity = action.payload
      break
    case SET_INVESTMENT_STYLE:
      state.investmentStyle = action.payload
      break
    case SET_US_DOLLAR_SENSITIVITY:
      state.usDollarSensitivity = action.payload
      break
    case SET_VOLATILITY_PREFERENCE:
      state.volatilityPreference = action.payload
      break
    case SET_AUTOMATION:
      state.automation = action.payload
      break
    default:
      break
  }

  return state
}

/**
 * ACTIONS
 */
export const clearFactorTilts = (): ReducerAction => {
  return { type: CLEAR_FACTOR_TILTS }
}
export const clearStrategy = (): ReducerAction => {
  return { type: CLEAR_STRATEGY }
}
export const evaluateImpactedTaxOptimizationFields = (): ReducerAction => {
  return { type: EVALUATE_IMPACTED_TAX_OPTIMIZATION_FIELDS }
}
export const setAssets = (assets: Asset[]): ReducerAction => {
  return { type: SET_ASSETS, payload: assets }
}
export const setAssetClass = (assetClass: AssetClass | null): ReducerAction => {
  return { type: SET_ASSET_CLASS, payload: assetClass }
}
export const setAutomation = (automation: boolean | null): ReducerAction => {
  return { type: SET_AUTOMATION, payload: automation }
}
export const setBenchmarkId = (benchmarkId: string | null): ReducerAction => {
  return {
    type: SET_BENCHMARK_ID,
    payload: benchmarkId,
  }
}
export const setBuyListId = (buyListId: string | null): ReducerAction => {
  return {
    type: SET_BUY_LIST_ID,
    payload: buyListId,
  }
}
export const setClientAccountId = (clientAccountId: string): ReducerAction => {
  return { type: SET_CLIENT_ACCOUNT_ID, payload: clientAccountId }
}
export const setDefaultState = (): ReducerAction => {
  return {
    type: DEFAULT_STATE,
  }
}
export const setDisplayName = (name: string): ReducerAction => {
  return {
    type: SET_DISPLAY_NAME,
    payload: name,
  }
}
export const setEnableTaxOptimization = (
  isEnabled: boolean | null
): ReducerAction => {
  return { type: SET_ENABLE_TAX_OPTIMIZATION, payload: isEnabled }
}
export const setExcludedAssets = (excludedAssets: string[]): ReducerAction => {
  return { type: SET_EXCLUDED_ASSETS, payload: excludedAssets }
}
export const setExcludedIndustries = (
  excludedIndustries: string[]
): ReducerAction => {
  return { type: SET_EXCLUDED_INDUSTRIES, payload: excludedIndustries }
}
export const setExcludedSectors = (
  excludedSectors: string[]
): ReducerAction => {
  return { type: SET_EXCLUDED_SECTORS, payload: excludedSectors }
}
export const setFractionalShares = (
  isFractionalSharesEnabled: boolean | null
): ReducerAction => {
  return { type: SET_FRACTIONAL_SHARES, payload: isFractionalSharesEnabled }
}
export const setPerformancePriority = (priorityNumber: PerformancePriority) => {
  return {
    type: SET_PERFORMANCE_PRIORITY,
    payload: priorityNumber,
  }
}
export const setStrategy = (strategy?: Strategies | null): ReducerAction => {
  return {
    type: SET_STRATEGY,
    payload: strategy,
  }
}
export const setTargetAssetCount = (count: number): ReducerAction => {
  return {
    type: SET_DEFAULT_TARGET_ASSET_COUNT,
    payload: count,
  }
}
export const setTargetCashWeightPercent = (
  targetCashWeightPercent: number
): ReducerAction => {
  return {
    type: SET_DEFAULT_TARGET_CASH_WEIGHT_PERCENT,
    payload: targetCashWeightPercent,
  }
}
export const setUserApprovedAutomatedTradesOnce = (
  isApprovedOnce: boolean
): ReducerAction => {
  return {
    type: SET_USER_APPROVED_AUTOMATED_TRADES_ONCE,
    payload: isApprovedOnce,
  }
}
// Factor Tilts
export const setFactorTilts = (
  factorTilts?: FactorTiltObject[]
): ReducerAction => {
  return {
    type: SET_FACTOR_TILTS,
    payload: factorTilts,
  }
}
export const setGlobalMacroSensitivity = (
  globalMacroSensitivity: string
): ReducerAction => {
  return {
    type: SET_GLOBAL_MACRO_SENSITIVITY,
    payload: globalMacroSensitivity,
  }
}
export const setInterestRateSensitivity = (
  interestRateSensitivity: string
): ReducerAction => {
  return {
    type: SET_INTEREST_RATE_SENSITIVITY,
    payload: interestRateSensitivity,
  }
}
export const setInvestmentStyle = (investmentStyle: string): ReducerAction => {
  return {
    type: SET_INVESTMENT_STYLE,
    payload: investmentStyle,
  }
}
export const setUsDollarSensitivity = (
  usDollarSensitivity: string
): ReducerAction => {
  return {
    type: SET_US_DOLLAR_SENSITIVITY,
    payload: usDollarSensitivity,
  }
}
export const setVolatilityPreference = (
  volatilityPreference: string
): ReducerAction => {
  return {
    type: SET_VOLATILITY_PREFERENCE,
    payload: volatilityPreference,
  }
}
