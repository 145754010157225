import { CreateClientAccountDto } from 'types/dto/ClientAccount.dto'

import ClientAccountForm, {
  AccountFields,
} from 'components/partials/Account/ClientAccountForm'
import { accountDetailsInitialValues } from 'components/partials/Forms/AccountDetailsFormFields'
import SlideOutMenu from 'components/partials/SlideOutMenu/SlideOutMenu'
import { closeAddAccount } from 'store/modalsSlice'
import { useAppDispatch, useAppSelector } from 'store/storeHooks'

type Props = {
  onCreateClientAccount: (account: CreateClientAccountDto) => void
}
function AddAccountModal({ onCreateClientAccount }: Props) {
  const dispatch = useAppDispatch()
  const { showAddAccount } = useAppSelector((state) => state.modals)
  const { managementOrganizationId } = useAppSelector((state) => state.user)

  const onFormSubmit = (values: AccountFields) => {
    let timeZone = new Intl.DateTimeFormat('en-us', { timeZoneName: 'short' })
      .formatToParts(new Date())
      .find((part) => part.type === 'timeZoneName')?.value

    const { displayName, description, primaryContact } = values
    onCreateClientAccount({
      displayName: displayName.trim(),
      description,
      primaryContact,
      meetingPreferences: {},
      managementOrganizationId: managementOrganizationId as string,
      taskList: [],
      localTimezone: timeZone || '',
    })
  }
  return (
    <>
      <SlideOutMenu
        isOpen={showAddAccount}
        onClose={() => dispatch(closeAddAccount())}
        size="xl"
        formId="add-family"
        buttonText="Save"
      >
        <ClientAccountForm
          formId="add-family"
          onSubmit={onFormSubmit}
          initialValues={{
            displayName: '',
            description: '',
            primaryContact: accountDetailsInitialValues,
          }}
        />
      </SlideOutMenu>
    </>
  )
}
export default AddAccountModal
