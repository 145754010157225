import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react'
import { analytics } from 'analytics'
import { isBoolean } from 'lodash'
import { useState } from 'react'

import { InvestmentTemplate } from 'types'

import { paveApi } from 'api'
import { Box, Button, Dropdown, Text } from 'components/common'
import BulletList from 'components/common/BulletList/BulletList'
import CheckList, {
  ChecklistOption,
} from 'components/common/CheckList/CheckList'
import FlexBox from 'components/common/FlexBox/FlexBox'
import { DropdownOptionString } from 'components/partials/DropdownWithTags'
import ApplyTemplateAutomationConfirmDialog from 'components/partials/Settings/ApplyTemplateAutomationConfirmDialog'
import {
  INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED,
  INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_CONFLICT,
  INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_FAILED,
  INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_SUCCESS,
} from 'constants/track.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

import ModalLoader from '../ModalLoader'

type Props = {
  isOpen: boolean
  onClose: () => void
  selectedPortfolios: ChecklistOption[]
  setSelectedPortfolios: React.Dispatch<React.SetStateAction<ChecklistOption[]>>
  templates: InvestmentTemplate[]
  refetch: () => void
}
function ApplyTemplateModal({
  isOpen,
  onClose,
  selectedPortfolios,
  setSelectedPortfolios,
  templates,
  refetch,
}: Props) {
  const [bulkApplyInvestmentTemplate, { isLoading }] =
    paveApi.useBulkApplyInvestmentTemplateMutation()
  const { showErrorToast, showSuccessToast } = useCustomToast()

  const [isAutomationConfirmOpen, setIsAutomationConfirmOpen] = useState(false)
  const [templateAutomation, setTemplateAutomation] = useState<
    boolean | null | undefined
  >(null)
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null
  )

  const templateOptions = templates?.map((c) => {
    return {
      value: c.id,
      label: c.displayName,
    }
  }) as DropdownOptionString[]
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [errorPortfolios, setErrorPortfolios] = useState<string[]>([])

  const handleSave = (templateId: string) => {
    analytics.track(INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED)
    bulkApplyInvestmentTemplate({
      templateId,
      portfolioIds: selectedPortfolios.map((portfolio) => portfolio.value),
    })
      .unwrap()
      .then((response) => {
        if (response.error) {
          showErrorToast(
            'Could not apply investment template',
            'There are issues with one or more of the portfolios you tried to apply this template to.'
          )
          setErrorMessage(response.displayMessage ?? '')
          setErrorPortfolios(response.portfolios as string[])
          analytics.track(INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_CONFLICT)
        } else {
          showSuccessToast('Template applied!')
          analytics.track(INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_SUCCESS)
          onClose()
          refetch()
        }
      })
      .catch(() => {
        showErrorToast('An error occurred when applying the template.')
        analytics.track(INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_FAILED)
      })
  }

  const handleApply = () => {
    if (selectedTemplateId) {
      const template = templates.filter((t) => t.id === selectedTemplateId)
      const selectedTemplateAutomation =
        template[0]?.investmentPreferences.automation

      if (isBoolean(selectedTemplateAutomation)) {
        setTemplateAutomation(selectedTemplateAutomation)
        const allSelectedPortfoliosHaveAutomation = selectedPortfolios.every(
          (portfolio) => portfolio?.extraData?.automation
        )

        // Show Automation Confirm modal if the selected template automation is different from the
        // automation of any of the selected portfolios or if the selected template automation is false
        // Example: If the selected template automation is true and any of the selected portfolios
        // have automation set to false, show the modal
        if (
          !selectedTemplateAutomation ||
          !allSelectedPortfoliosHaveAutomation
        ) {
          setIsAutomationConfirmOpen(true)
          return
        }
      }

      handleSave(selectedTemplateId)
    } else {
      showErrorToast('A template must be selected in order to submit.')
      analytics.track(INVESTMENT_TEMPLATE_BULK_APPLY_SUBMITTED_FAILED)
    }
  }

  const clearErrors = () => {
    setErrorMessage('')
    setErrorPortfolios([])
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          clearErrors()
        }}
        size="xl"
      >
        <ModalOverlay bg="rgba(0, 0, 0, 0.2)" />
        <ModalContent>
          <ModalHeader>
            <Box>
              <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs16}>
                Apply Template
              </Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingX={SPACE.sp24}>
            <FlexBox flexDirection="column" gap={SPACE.sp12}>
              <Text
                color={COLOR.coolGray600}
                fontSize={FONT_SIZE.fs12}
                fontWeight={500}
              >
                Once you apply a template, portfolio investment preferences will
                update immediately and any suggested trades will be cleared. It
                may take a while for the suggested trades to be updated. Any
                affected portfolios set to trade today, will now trade during
                the following trading day.
              </Text>
              <Dropdown
                label="Investment Template"
                placeholder="Select an option"
                options={templateOptions}
                onChange={(option: unknown) => {
                  const { value } = option as { value: string }
                  setSelectedTemplateId(value)
                }}
                value={selectedTemplateId}
              />
              <FlexBox
                flexDirection="column"
                gap={SPACE.sp8}
                marginTop={SPACE.sp10}
                maxHeight="20rem"
                position="relative"
                overflow="hidden"
              >
                <CheckList
                  allOptions={selectedPortfolios}
                  headerLabel="Affected Portfolios"
                  selectedOptions={selectedPortfolios}
                  setSelectedOptions={setSelectedPortfolios}
                />
              </FlexBox>
            </FlexBox>
          </ModalBody>
          <ModalFooter marginBottom={SPACE.sp8}>
            <FlexBox flexDirection="column" gap={SPACE.sp8} width="100%">
              {errorMessage && (
                <Text
                  color={COLOR.red500}
                  fontWeight="medium"
                  fontSize={FONT_SIZE.fs12}
                  lineHeight="xxs"
                  mt="sm"
                  mb={0}
                  as="p"
                >
                  <FlexBox
                    flexDirection="column"
                    gap={SPACE.sp8}
                    marginBottom={SPACE.sp8}
                  >
                    {errorMessage}:
                    <BulletList
                      items={errorPortfolios}
                      textColor={COLOR.red500}
                    />
                  </FlexBox>
                </Text>
              )}
              <Box marginBottom={SPACE.sp12} width="100%">
                <Button
                  onClick={handleApply}
                  size="md"
                  variant="primary"
                  width="100%"
                  disabled={
                    !selectedTemplateId ||
                    selectedPortfolios.length < 1 ||
                    isLoading
                  }
                >
                  Apply
                </Button>
                {isLoading && <ModalLoader />}
              </Box>
            </FlexBox>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isBoolean(templateAutomation) && (
        <ApplyTemplateAutomationConfirmDialog
          isAutomationConfirmDialogOpen={isAutomationConfirmOpen}
          setIsAutomationConfirmDialogOpen={setIsAutomationConfirmOpen}
          onConfirm={() => {
            selectedTemplateId && handleSave(selectedTemplateId)
          }}
          automation={templateAutomation}
        />
      )}
    </>
  )
}

export default ApplyTemplateModal
