import styled from '@emotion/styled'
import {
  ArrowDownIcon,
  ArrowUpIcon,
  HomeIcon,
  MailIcon,
  MenuAlt2Icon,
  PhoneIcon,
} from '@heroicons/react/solid'
import { isNull } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'

import { ClientAccountDto } from 'types/dto/ClientAccount.dto'

import { Box, Tag, Text } from 'components/common'
import InteractiveCard from 'components/common/Card/InteractiveCard'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatAsCurrency } from 'utils'

type Props = ClientAccountDto

function AccountCard({
  id,
  displayName,
  portfolios,
  primaryContact,
  balance,
  gainPercentage,
}: Props) {
  const handleEmailClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation()
    window.location.href = `mailto:${primaryContact.email}`
  }

  const gainPercentageAmount =
    isNaN(gainPercentage) || isNull(gainPercentage) ? 0.0 : gainPercentage

  return (
    <InteractiveCard
      display="flex"
      flexDirection="column"
      gap={SPACE.sp20}
      minHeight=" 216px"
      width="17.75rem"
    >
      <Link to={`/account/${id}`}>
        <HeaderContainer>
          <Text
            className="interactive-text"
            fontWeight="bold"
            fontSize="xl"
            lineHeight="xl"
            color="coolGray.800"
            wrapText
            style={{ cursor: 'pointer' }}
          >
            {displayName}
          </Text>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            padding="0px"
            gap="8px"
            height="24px"
          >
            <Text
              fontWeight="normal"
              fontSize="md"
              lineHeight="md"
              color="coolGray.700"
            >
              {formatAsCurrency(balance)}
            </Text>

            <Tag variant={gainPercentageAmount >= 0 ? 'green' : 'red'}>
              {gainPercentageAmount >= 0 ? (
                <ArrowUpIcon width={12} height={12} />
              ) : (
                <ArrowDownIcon width={12} height={12} />
              )}
              {gainPercentageAmount}%
            </Tag>
          </Box>
        </HeaderContainer>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          padding="0px"
          gap="16px"
          paddingTop="30px"
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            padding="0px"
            gap="12px"
          >
            <Box width="16px" height="16px" color="coolGray.500">
              <MenuAlt2Icon />
            </Box>
            <Text
              fontWeight="medium"
              fontSize="xs"
              lineHeight="xs"
              color="coolGray.700"
            >
              {`${portfolios.length} Portfolios`}
            </Text>
          </Box>

          {primaryContact.city && primaryContact.state && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding="0px"
              gap="12px"
            >
              <Box width="16px" height="16px" color="coolGray.500">
                <HomeIcon />
              </Box>
              <Text
                fontWeight="medium"
                fontSize="xs"
                lineHeight="xs"
                color="coolGray.700"
              >
                {primaryContact.city}, {primaryContact.state}
              </Text>
            </Box>
          )}

          {primaryContact.phoneNumber && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding="0px"
              gap="12px"
            >
              <Box width="16px" height="16px" color="coolGray.500">
                <PhoneIcon />
              </Box>
              <Text
                fontWeight="medium"
                fontSize="xs"
                lineHeight="xs"
                color="coolGray.700"
              >
                {primaryContact.phoneNumber}
              </Text>
            </Box>
          )}

          {primaryContact.email && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              padding="0px"
              gap="12px"
            >
              <Box color="coolGray.500">
                <MailIcon width={16} height={16} />
              </Box>

              <Text
                onClick={handleEmailClick}
                fontWeight="md"
                fontSize={FONT_SIZE.fs12}
                lineHeight="sm"
                color={COLOR.lightBlue600}
                wrapText
              >
                {primaryContact.email}
              </Text>
            </Box>
          )}
        </Box>
      </Link>
    </InteractiveCard>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
`

export default AccountCard
