import { Box, Switch, Text } from 'components/common'

type Props = {
  title: string
  description?: string
  value: boolean
  setValue: (value: boolean) => void
  disabled?: boolean
  children?: React.ReactNode
}

function Toggle({
  title,
  description,
  value,
  setValue,
  disabled,
  children,
}: Props) {
  const handleToggle = () => {
    setValue(!value)
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexDirection="column">
        <Text fontSize="sm" fontWeight="600" color="coolGray.500">
          {title}
        </Text>
        {description ? (
          <Text fontSize="sm" fontWeight="400" color="coolGray.500">
            {description}
          </Text>
        ) : (
          children
        )}
      </Box>
      <Box>
        <label>
          <Switch checked={value} onChange={handleToggle} disabled={disabled} />
        </label>
      </Box>
    </Box>
  )
}

export default Toggle
