import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from '@chakra-ui/react'
import styled from '@emotion/styled'
import { useState } from 'react'

import { Box, Button, Text } from 'components/common'
import { ThirdPartyTooltip, Toggle } from 'components/partials'
import { DEFAULT_IS_THIRD_PARTY_MANAGED } from 'constants/portfolio.constants'

import LinkPlaidModal from './LinkPlaidModal'

type Props = {
  goBack: () => void
  goNext: () => void
}

function PlaidFlowStep2({ goBack, goNext }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isThirdPartyManaged, setIsThirdPartyManaged] = useState(
    DEFAULT_IS_THIRD_PARTY_MANAGED
  )

  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <ModalHeader>Create New Portfolio</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Box display="flex" gap="24px" p="12px 0">
          <LightButton
            variant="secondary"
            size="sm"
            onClick={() => {
              setShowModal(true)
              onOpen()
            }}
          >
            + Add Brokerage Account
          </LightButton>
        </Box>
        <Box marginTop="12px">
          <Toggle
            title="3rd Party Managed"
            value={isThirdPartyManaged}
            setValue={setIsThirdPartyManaged}
          >
            <Box display="flex" flexDirection="row" gap={4}>
              <Text fontSize="sm" fontWeight="400" color="coolGray.500">
                Is the linked portfolio managed by a 3rd party?
              </Text>
              <ThirdPartyTooltip />
            </Box>
          </Toggle>
        </Box>
        <ModalFooter px="0">
          <Box
            display="flex"
            width="100%"
            flexDirection="column"
            gap="12px"
            mt="12px"
          >
            <Button
              color="purple.800"
              style={{ width: '100%' }}
              onClick={goNext}
              disabled
            >
              Next
            </Button>
            <Button variant="secondary" onClick={goBack} active={false}>
              Back
            </Button>
          </Box>
        </ModalFooter>
      </ModalBody>
      {showModal && (
        <LinkPlaidModal
          isOpen={isOpen}
          onClose={onClose}
          setShowModal={setShowModal}
          goNext={goNext}
          isThirdPartyManaged={isThirdPartyManaged}
        />
      )}
    </>
  )
}

const LightButton = styled(Button)`
  padding: 16px 30px;
  height: 78px;
  background-color: ${({ theme }) => theme.colors.coolGray[50]};
`

export default PlaidFlowStep2
