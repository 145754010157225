import { capitalize } from 'lodash'
import React, { useEffect } from 'react'

import { Allocation, AllocationFilter } from 'types'

import { paveApi } from 'api'
import { Box, Button, Separator, Text } from 'components/common'
import Card from 'components/common/Card/Card'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Spinner from 'components/common/Loader/Spinner'
import { DonutChart } from 'components/partials'
import AllocationSummaryItem from 'components/partials/Portfolio/AllocationSummaryItem'
import { chartColors } from 'constants/pieChart.constants'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'

type Props = {
  clientAccountId: string
  totalPortfolios: number
}

function AccountAllocation({ clientAccountId, totalPortfolios }: Props) {
  const [filter, setFilter] = React.useState<AllocationFilter>(
    AllocationFilter.Value
  )
  const [allocation, setAllocation] = React.useState<Allocation>()
  const { data, isLoading } =
    paveApi.useGetClientAccountBreakdownsQuery(clientAccountId)
  const accountHasValue = Boolean(data?.breakdowns?.length)

  useEffect(() => {
    const d = data?.breakdowns?.find((d) => {
      return d.name === filter
    })
    setAllocation(d)
  }, [data, filter])

  return (
    <Card
      display="flex"
      flexWrap="wrap"
      overflowY="scroll"
      minHeight="17.25rem"
      maxHeight="32.75rem"
    >
      <Box width="100%" height="100%">
        <Text
          fontSize={FONT_SIZE.fs20}
          fontWeight="bold"
          color={COLOR.coolGray600}
        >
          Group Allocation
        </Text>
        {isLoading ? (
          <FlexBox
            flexDirection="column"
            justifyContent="center"
            height="100%"
            margin="auto"
            width="fit-content"
          >
            <Spinner size="small" />
          </FlexBox>
        ) : (
          <>
            {accountHasValue && allocation ? (
              <>
                <FlexBox width="100%" marginBottom="0.5rem">
                  {Object.entries(AllocationFilter).map(([key, value]) => {
                    return (
                      <Button
                        variant="tertiary"
                        size="xs"
                        active={filter === value}
                        onClick={() => {
                          setFilter(value)
                        }}
                        key={key}
                      >
                        {key}
                      </Button>
                    )
                  })}
                </FlexBox>
                <Box width="100%">
                  <Box width="100%">
                    <FlexBox justifyContent="center">
                      <DonutChart
                        data={allocation}
                        colors={chartColors}
                        title={{
                          text: `<b>${capitalize(filter)}</b>`,
                        }}
                      />
                    </FlexBox>
                    <Box paddingY={SPACE.sp10}>
                      {allocation.labels.map((label: string, index: number) => (
                        <div key={index}>
                          <AllocationSummaryItem
                            color={chartColors[index]}
                            label={label}
                            value={allocation.values[index]}
                          />
                          {index !== allocation.labels.length - 1 && (
                            <Separator
                              strokeWidth={1}
                              color={COLOR.coolGray300}
                            />
                          )}
                        </div>
                      ))}
                    </Box>
                  </Box>
                </Box>
              </>
            ) : (
              <FlexBox
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="85%"
                width="100%"
              >
                <Text
                  fontSize={FONT_SIZE.fs28}
                  fontWeight="700"
                  color={COLOR.coolGray600}
                >
                  {totalPortfolios}
                </Text>
                <Text
                  fontSize={FONT_SIZE.fs14}
                  fontWeight="500"
                  color={COLOR.coolGray600}
                >
                  {totalPortfolios === 1 ? 'Portfolio' : 'Portfolios'}
                </Text>
              </FlexBox>
            )}
          </>
        )}
      </Box>
    </Card>
  )
}

export default AccountAllocation
