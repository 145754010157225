import { ExclamationCircleIcon } from '@heroicons/react/outline'

import { SuggestedTradesStatus } from 'enums/SuggestedTradesStatusEnum'
import {
  GetPortfolioStatsResponse,
  Portfolio,
  PortfolioLinkType,
  TradingStatus,
} from 'types'

import { Box, Button, Tag, Text } from 'components/common'
import FlexBox from 'components/common/FlexBox/FlexBox'
import Spinner from 'components/common/Loader/Spinner'
import { openPortfolioAllSuggestedTrades } from 'store/modalsSlice'
import { useAppDispatch } from 'store/storeHooks'
import { COLOR } from 'styles/constants/color'
import { FONT_SIZE } from 'styles/constants/fontSize'
import { SPACE } from 'styles/constants/space'
import { formatDateMMDDYYYY } from 'utils/dateUtil'
import {
  getFirstPlaidBrokerageAccount,
  shouldUpdatePlaidLink,
} from 'utils/portfolioUtil'

import UpdatePlaidLink from '../UpdatePlaidLink'
import PortfolioStats from './PortfolioStats'

type Props = {
  statistics?: GetPortfolioStatsResponse
  isStatisticsLoading?: boolean
  isPortfolioFetching?: boolean
  portfolio?: Portfolio
  linkType: PortfolioLinkType
  isLocked?: boolean
  isAutomationSupported?: boolean
  isPortfolioLoading?: boolean
  editPortfolio?: () => void
  showEditPortfolioSlideIn: () => void
  refetchPortfolio: () => void
}

function PortfolioSuggestedTrades({
  portfolio,
  linkType,
  statistics,
  isStatisticsLoading,
  isPortfolioFetching,
  isLocked = false,
  isAutomationSupported,
  isPortfolioLoading,
  editPortfolio,
  showEditPortfolioSlideIn,
  refetchPortfolio,
}: Props) {
  const dispatch = useAppDispatch()

  const {
    suggestedTrades,
    investmentPreference,
    userApprovedAutomatedTradesOnce,
    isThirdPartyManaged,
  } = portfolio || {}

  const suggestedTradesLength = suggestedTrades?.length || 0
  const disableSuggestedTrades =
    isLocked ||
    isThirdPartyManaged ||
    suggestedTradesLength === 0 ||
    portfolio?.suggestedTradesStatus === SuggestedTradesStatus.NEEDS_UPDATE

  const handleOpenAllSuggestedTradesModal = () => {
    dispatch(openPortfolioAllSuggestedTrades())
  }

  const tradesScheduled =
    portfolio?.tradingStatus === TradingStatus.NEEDS_TO_BE_MARKED_FOR_TRADING ||
    portfolio?.tradingStatus ===
      TradingStatus.NEEDS_TO_BE_MARKED_FOR_TRADING_USER_REQUEST

  if (isPortfolioLoading || isPortfolioFetching) {
    return (
      <FlexBox
        alignItems="center"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Spinner size="small" />
      </FlexBox>
    )
  }

  if (!portfolio?.isInvestmentPreferenceCompleted && !isThirdPartyManaged) {
    return (
      <FlexBox flexDirection="column" alignItems="flex-start" gap={SPACE.sp24}>
        <Text
          fontWeight="bold"
          fontSize={FONT_SIZE.fs20}
          lineHeight="xl"
          color={COLOR.coolGray600}
        >
          Pending Settings Completion
        </Text>
        <Text fontSize={FONT_SIZE.fs14} color={COLOR.coolGray700} as="p">
          Please finish setting up your investment preferences to get access to
          Pave's trade suggestions You can always change your investment
          preferences through the investment preferences menu in in the
          portfolio preferences panel below.
        </Text>
        <Button
          type="submit"
          variant="primary"
          size="lg"
          onClick={showEditPortfolioSlideIn}
        >
          Finalize Investment Preferences
        </Button>
      </FlexBox>
    )
  }

  if (
    portfolio &&
    linkType === PortfolioLinkType.Plaid &&
    shouldUpdatePlaidLink(portfolio)
  ) {
    return (
      <UpdatePlaidLink
        brokerageAccount={getFirstPlaidBrokerageAccount(portfolio)}
        refetchPortfolio={refetchPortfolio}
      />
    )
  }

  let tradeContent

  if (!isAutomationSupported) {
    // initial state is manual approval
    tradeContent = (
      <PortfolioStats
        statistics={statistics}
        isLoading={isStatisticsLoading}
        isThirdPartyManaged={isThirdPartyManaged}
        editPortfolio={editPortfolio}
        portfolioNeedsTradeExecution={isLocked}
        hasSuggestedTrades={suggestedTradesLength > 0}
      />
    )
    if (
      portfolio?.suggestedTradesStatus === SuggestedTradesStatus.NEEDS_UPDATE
    ) {
      tradeContent = (
        <Text fontSize={FONT_SIZE.fs14} color={COLOR.coolGray600}>
          Your portfolio is in the process of building new suggested trades.
        </Text>
      )
    }
  } else {
    // initial state is manual approval
    tradeContent = (
      <Text fontSize={FONT_SIZE.fs14}>
        Please review all suggested trades and execute them with one click.
      </Text>
    )
    if (isLocked) {
      tradeContent = (
        <Text fontSize={FONT_SIZE.fs14}>
          Trade execution is in progress. During this time your investment
          preferences are frozen and you will not be able to execute additional
          trades.
        </Text>
      )
    } else if (
      portfolio?.suggestedTradesStatus === SuggestedTradesStatus.NEEDS_UPDATE
    ) {
      tradeContent = (
        <Text fontSize={FONT_SIZE.fs14} color={COLOR.coolGray600}>
          Your portfolio is in the process of building new suggested trades.
        </Text>
      )
    } else if (suggestedTradesLength === 0) {
      tradeContent = (
        <Text fontSize={FONT_SIZE.fs14} color={COLOR.coolGray600}>
          Your portfolio is currently well positioned for market conditions
        </Text>
      )
    } else if (investmentPreference?.automation) {
      const automationText = userApprovedAutomatedTradesOnce ? (
        <Text fontSize={FONT_SIZE.fs14}>
          Please review all suggested trades.
        </Text>
      ) : (
        <>
          <Text fontSize={FONT_SIZE.fs14}>
            Please review and approve all suggested trades. Since you turned on
            automation, you will only have to do this once. Going forward, Pave
            will automatically place suggested trades as opportunities arise.
          </Text>
          <Button
            variant="primary"
            size="md"
            onClick={() => dispatch(openPortfolioAllSuggestedTrades())}
            disabled={isLocked}
            style={{ marginTop: '-0.625rem' }}
          >
            Review & Approve Trades
          </Button>
        </>
      )

      tradeContent = (
        <>
          {automationText}
          <PortfolioStats
            statistics={statistics}
            isLoading={isStatisticsLoading}
            isThirdPartyManaged={isThirdPartyManaged}
            editPortfolio={editPortfolio}
            portfolioNeedsTradeExecution={isLocked}
            hasSuggestedTrades={suggestedTradesLength > 0}
          />
        </>
      )
    } else {
      tradeContent = (
        <PortfolioStats
          statistics={statistics}
          isLoading={isStatisticsLoading}
          isThirdPartyManaged={isThirdPartyManaged}
          editPortfolio={editPortfolio}
          portfolioNeedsTradeExecution={isLocked}
          hasSuggestedTrades={suggestedTradesLength > 0}
        />
      )
    }
  }

  if (isStatisticsLoading) {
    tradeContent = (
      <FlexBox
        flexDirection="column"
        justifyContent="center"
        height="100%"
        margin="auto"
      >
        <Spinner size="small" />
      </FlexBox>
    )
  }

  return (
    <FlexBox
      flex={1}
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      width="100%"
    >
      <FlexBox flexDirection="column" gap={SPACE.sp24}>
        <FlexBox justifyContent="space-between" height="2rem" width="100%">
          {userApprovedAutomatedTradesOnce ||
          !isAutomationSupported ||
          !investmentPreference?.automation ||
          isLocked ? (
            <FlexBox
              alignContent="space-between"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Text
                fontWeight="bold"
                fontSize={FONT_SIZE.fs20}
                lineHeight="xl"
                color={COLOR.coolGray600}
              >{`Suggested Trades ${
                disableSuggestedTrades ? '' : `(${suggestedTradesLength})`
              }`}</Text>

              <FlexBox gap={SPACE.sp12} alignItems="center">
                {tradesScheduled && (
                  <Tag variant="green">
                    <Text fontSize={FONT_SIZE.fs10}>&#9679;</Text>
                    Trades Scheduled
                  </Tag>
                )}
                <Button
                  size="sm"
                  onClick={handleOpenAllSuggestedTradesModal}
                  disabled={disableSuggestedTrades || isStatisticsLoading}
                >
                  View Suggested Trades
                </Button>
              </FlexBox>
            </FlexBox>
          ) : (
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Box width="1.5rem" height="1.5rem">
                <ExclamationCircleIcon color={COLOR.purple700} />
              </Box>
              <Text
                fontWeight="bold"
                fontSize={FONT_SIZE.fs20}
                lineHeight="xl"
                color={COLOR.coolGray700}
                style={{ paddingInline: 5 }}
              >
                Approve Suggested Trades
              </Text>
            </Box>
          )}
        </FlexBox>
        {tradeContent}
      </FlexBox>
      {portfolio?.lastTradedAt && (
        <FlexBox alignItems="center">
          <Box
            backgroundColor={COLOR.emerald600}
            borderRadius="100%"
            height="0.375rem"
            marginRight={SPACE.sp8}
            width="0.375rem"
          />
          <Text color={COLOR.coolGray600} fontSize={FONT_SIZE.fs14}>
            Last trades were placed on{' '}
            {formatDateMMDDYYYY(new Date(portfolio.lastTradedAt))}
          </Text>
        </FlexBox>
      )}
    </FlexBox>
  )
}

export default PortfolioSuggestedTrades
