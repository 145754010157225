import { STATUS_ENUM } from 'types'
import { BuyListFormPayload } from 'types/buyListTypes'

import { paveApi } from 'api'
import { Box, Button } from 'components/common'
import { SlideOutMenu } from 'components/partials'
import { EMPTY_BUY_LIST_STATE } from 'constants/buyList.constants'
import { useCustomToast } from 'hooks/useCustomToast'
import { SPACE } from 'styles/constants/space'

import BuyListForm from './BuyLists/BuyListForm'

type Props = {
  closeModal: () => void
  showModal: boolean
}

function CreateBuyListSlideIn({ closeModal, showModal }: Props) {
  const { showErrorToast, showSuccessToast } = useCustomToast()
  const [createBuyList, { isLoading }] = paveApi.useCreateBuyListMutation()
  const submitBuyListForm = (createBuyListRequest: BuyListFormPayload) => {
    createBuyList(createBuyListRequest)
      .unwrap()
      .then((res) => {
        if (res?.status === STATUS_ENUM.ERROR) throw res
        showSuccessToast('Buy list created!')
        closeModal()
      })
      .catch((e) => {
        if (e?.status === STATUS_ENUM.ERROR) {
          showErrorToast(e.errorMessage)
        } else {
          showErrorToast('Error creating buy list.')
        }
      })
  }

  return (
    <SlideOutMenu
      isOpen={showModal}
      onClose={closeModal}
      title="Create Buy List"
      size="xl"
      formId="buy-list-form"
      customHeaderButtons={
        <Box display="flex" gap={SPACE.sp12}>
          <Button type="submit" form="buy-list-form">
            Create
          </Button>
        </Box>
      }
    >
      <BuyListForm
        initialBuyList={EMPTY_BUY_LIST_STATE}
        showLoader={isLoading}
        isNewBuyList={true}
        submitBuyListForm={submitBuyListForm}
      />
    </SlideOutMenu>
  )
}
export default CreateBuyListSlideIn
